<template>
  <v-card :outlined="outlined" height="100%" class="d-flex flex-column">
    <div>
      <v-img aspect-ratio="1.778" :src="img"></v-img>
    </div>
    <v-card-title>
      <span class="font-weight-light text-h5">{{ card.title }}</span>
    </v-card-title>
    <v-divider width="70%"></v-divider>
    <v-card-text
      class="
        align-self-end
        card__text
        flex-grow-1
        d-flex
        flex-column
        text-justify
      "
    >
      <p>{{ card.pretext }}</p>
      <v-spacer></v-spacer>
      <p class="justify-self-end mt-2 mb-0" v-html="card.posttext"></p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Card",
  props: {
    card: {
      type: Object,
      required: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    img() {
      return require(`@/assets/img/cards/${this.card.img}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  &__text {
    & p {
      //height: 50%;
    }
  }
}
.italic {
  font-style: italic;
}
</style>